export default {
  stage: process.env.NODE_ENV || 'development',
  production: {
    ws_server: "https://api.ezlead.io",
    calls_server: "https://calls.ezlead.io",
    inventory_server: "https://inventory.ezlead.io",
    files_server: "https://files.ezlead.io",
    cdn_server: "https://cdn.ezlead.io",
    program_server: "https://program.ezlead.io",
    support_center: "https://support.ezlead.io",
    car_stats_server: "https://carstats.ezlead.io",
    billing: "https://billing.ezlead.io",
    mailing: "https://mailing.ezlead.io",
    reviews: "https://reviews.ezlead.io",
    short_url: "https://ez-l.cc",
    meet: "https://meet.ezlead.io",
    meet_server: "https://meet-server.ezlead.io",
    html: "https://html.ezlead.io",
    data_server: "https://data.ezlead.io",
    ftp_server: "http://ftp.ezlead.io",
    ftp_web_server: "https://ftp.ezleads.io",
    chat_bot_server: "https://chatbot.ezleads.io",
    s3_url: "https://static.ezleadscdn.com/p/",

    fb_app_id: "711594624348779",
    fb_configuration_id: "334182589725127",
    fb_app_secret: "1b69a5900b578285b8f1b56eac3107a5",
    fb_webwook_token: "",

    google_app_id: "645261426945-2frg39r8ei598asagql7vfujg3qa0599.apps.googleusercontent.com",
    google_app_secret: "yMlf6cLddr3uroJeYyp_uwNT",
    google_app_key: "AIzaSyBB8f81cL4nvDUEH-tiT_lzQ5mX_Bgk6Po",
    google_maps_api_key: "AIzaSyD3LLgn3Nl-eOKdPYX6RrivJ5O1suC-DNw",

    stripe_key: "pk_live_oxnbaMSlVuUE88nQ2YgnURRV006TjJfgnh"
  },
  development1: {
    ws_server: "https://api.ezlead.io",
    calls_server: "https://calls.ezlead.io",
    inventory_server: "https://inventory.ezlead.io",
    files_server: "https://files.ezlead.io",
    cdn_server: "https://cdn.ezlead.io",
    program_server: "https://program.ezlead.io",
    support_center: "https://support.ezlead.io",
    car_stats_server: "https://carstats.ezlead.io",
    billing: "https://billing.ezlead.io",
    mailing: "https://mailing.ezlead.io",
    reviews: "https://reviews.ezlead.io",
    short_url: "https://ez-l.cc",
    meet: "https://meet.ezlead.io",
    meet_server: "https://meet-server.ezlead.io",
    html: "https://html.ezlead.io",
    data_server: "https://data.ezlead.io",
    ftp_server: "http://ftp.ezlead.io",
    ftp_web_server: "https://ftp.ezleads.io",
    chat_bot_server: "https://chatbot.ezleads.io",
    s3_url: "https://static.ezleadscdn.com/p/",

    fb_app_id: "711594624348779",
    fb_configuration_id: "334182589725127",
    fb_app_secret: "1b69a5900b578285b8f1b56eac3107a5",
    fb_webwook_token: "",

    google_app_id: "645261426945-2frg39r8ei598asagql7vfujg3qa0599.apps.googleusercontent.com",
    google_app_secret: "yMlf6cLddr3uroJeYyp_uwNT",
    google_app_key: "AIzaSyBB8f81cL4nvDUEH-tiT_lzQ5mX_Bgk6Po",
    google_maps_api_key: "AIzaSyD3LLgn3Nl-eOKdPYX6RrivJ5O1suC-DNw",

    stripe_key: "pk_live_oxnbaMSlVuUE88nQ2YgnURRV006TjJfgnh"
  },
  development: {
    ws_server: "http://localhost:5000",
    calls_server: "http://localhost:4000",
    inventory_server: "http://localhost:4001",
    files_server: "http://localhost:4003",
    cdn_server: "http://localhost:4005",
    program_server: "http://localhost:4004",
    support_center: "http://localhost:4010",
    car_stats_server: "http://localhost:52004",
    billing: "http://localhost:4011",
    mailing: "http://localhost:4012",
    reviews: "http://localhost:8081",
    short_url: "http://localhost:52002",
    meet: "http://localhost:5200",
    meet_server: "http://localhost:52003",
    html: "http://localhost:52005",
    data_server: "http://localhost:52006",
    ftp_server: "http://localhost:4021",
    ftp_web_server: "http://localhost:4021",
    chat_bot_server: "http://localhost:52007",
    s3_url: "https://static.ezleadscdn.com/d/",
    s3_cdn: {
      images: "https://static.ezleadscdn.com/d/images/",
      videos: "https://static.ezleadscdn.com/d/videos/",
      documents: "https://static.ezleadscdn.com/d/documents/",
    },

    fb_app_id: "485633427443225",
    fb_configuration_id: "334182589725127",
    fb_app_secret: "1b69a5900b578285b8f1b56eac3107a5",
    fb_webwook_token: "",

    google_app_id: "645261426945-2frg39r8ei598asagql7vfujg3qa0599.apps.googleusercontent.com",
    google_app_secret: "yMlf6cLddr3uroJeYyp_uwNT",
    google_app_key: "AIzaSyBB8f81cL4nvDUEH-tiT_lzQ5mX_Bgk6Po",
    google_maps_api_key: "AIzaSyDOU4W7jBf7Uh26ZHsShPFMNsQSHUt5PJY",

    stripe_key: "pk_test_ZeCNvUmcy6ApZnocLkz6qd4Y00AaAFFiJx"
  },
  staging: {
    ws_server: "https://api.ezleadsdev.com",
    calls_server: "https://calls.ezleadsdev.com",
    inventory_server: "https://inventory.ezleadsdev.com",
    files_server: "https://files.ezleadsdev.com",
    cdn_server: "https://cdn.ezleadsdev.com",
    program_server: "https://program.ezleadsdev.com",
    support_center: "https://support.ezleadsdev.com",
    car_stats_server: "https://carstats.ezleadsdev.com",
    billing: "https://billing.ezleadsdev.com",
    mailing: "https://mailing.ezleadsdev.com",
    reviews: "https://reviews.ezleadsdev.com",
    short_url: "https://staging.ez-l.cc",
    meet: "https://meet.ezleadsdev.com",
    meet_server: "https://meet-server.ezleadsdev.com",
    html: "https://html.ezleadsdev.com",
    data_server: "https://data.ezleadsdev.com",
    ftp_server: "http://localhost:4021",
    ftp_web_server: "http://localhost:4021",
    chat_bot_server: "https://chatbot.ezleadsdev.com",
    s3_url: "https://static.ezleadscdn.com/s/",

    fb_app_id: "485633427443225",
    fb_configuration_id: "334182589725127",
    fb_app_secret: "1b69a5900b578285b8f1b56eac3107a5",
    fb_webwook_token: "",

    google_app_id: "645261426945-2frg39r8ei598asagql7vfujg3qa0599.apps.googleusercontent.com",
    google_app_secret: "yMlf6cLddr3uroJeYyp_uwNT",
    google_app_key: "AIzaSyBB8f81cL4nvDUEH-tiT_lzQ5mX_Bgk6Po",
    google_maps_api_key: "AIzaSyA6v7cwhTvEa6j2WVrEo4yHuqfNyJ7WoM4",

    stripe_key: "pk_test_ZeCNvUmcy6ApZnocLkz6qd4Y00AaAFFiJx"
  }
};
